import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { PACKMAN_API_URL } from '../appConstants';
import { dispatch } from '../store/Index';
import { generalShowErrorSnackbar } from '../store/general/Functions';
import { authSetPPECBToken, authSetTURToken } from '../store/auth/Actions';
import { IConsignmentTokenResponse } from '../@types/model/compliance/titan-TUR/consignmentTokenResponse';
import { ITURTokenResponse } from '../@types/model/compliance/titan-TUR/turTokenResponse';
import { IConsignmentResponse } from '../@types/model/compliance/titan-TUR/consignmentResponse';
import { IConsignment } from '../@types/model/compliance/titan-TUR/consignment';
import { IConsignmentPostResponse } from '../@types/model/compliance/titan-TUR/consignmentPostResponse';
import { IConsignmentValidationResponse } from '../@types/model/compliance/titan-TUR/consignmentValidationResponse';
import { IELot } from '../@types/model/compliance/titan-TUR/eLot';
import { IELotResponse } from '../@types/model/compliance/titan-TUR/eLotResponse';
import { ITradeUnitResponse } from '../@types/model/compliance/titan-TUR/tradeUnitResponse';

export const getConsignmentToken = () : AxiosPromise<IConsignmentTokenResponse> => axios.get(PACKMAN_API_URL + 'Consignment/GetPPECBToken').then((res : AxiosResponse<IConsignmentTokenResponse>) => {
    if (res?.data) {
        dispatch(authSetPPECBToken(res?.data));
        return res?.data;
    }
}).catch((err) => { generalShowErrorSnackbar('An error occurred getting a consignment token.'); return err; });

export const getTURToken = () : AxiosPromise<Array<ITURTokenResponse>> => axios.get(`${PACKMAN_API_URL}Consignment/TUMToken`).then((res : AxiosResponse<ITURTokenResponse>) => {
    if (res?.data) {
        dispatch(authSetTURToken(res?.data));
        return res?.data;
    }
}).catch((err) => { generalShowErrorSnackbar('An error occurred getting a TUR token.'); return err; });

export const getConsignmentResult = (consignmentNumber : string) : AxiosPromise<IConsignmentResponse> => axios.get(`${PACKMAN_API_URL}Consignment/GetConsignmentPPECBResult?consignmentNumber=${consignmentNumber}`).then(res => res).catch((err) => { generalShowErrorSnackbar('An error occurred retrieving the consignment.'); return err; });

export const uploadConsignment = (consignment : IConsignment) : AxiosPromise<IConsignmentPostResponse> => {
    return axios.post(`${PACKMAN_API_URL}Consignment/UploadConsignmentToPPECB`, consignment).then(res => res).catch(err => err);
};

export const updateConsignment = (consignment : IConsignment) : AxiosPromise<IConsignmentPostResponse> => {
    return axios.post(`${PACKMAN_API_URL}Consignment/UpdatePPECBConsignment`, consignment).then(res => res).catch(err => err);
};

export const deleteConsignment = (inspectionMessageId : number) : AxiosPromise<IConsignmentPostResponse> => {
    return axios.delete(`${PACKMAN_API_URL}Consignment/DeletePPECBConsignment`, {
        params : { inspectionMessageId },
    })
        .then(res => res)
        .catch((err) => { generalShowErrorSnackbar('An error occurred validating the consignment.'); return err; });
};

export const validateConsignment = (inspectionMessageId : number) : AxiosPromise<IConsignmentValidationResponse> => {
    return axios.get(`${PACKMAN_API_URL}Consignment/ValidateConsignment`, {
        params : { inspectionMessageId },
    })
        .then(res => res)
        .catch((err) => { generalShowErrorSnackbar('An error occurred validating the consignment.'); return err; });
};

export const getAgreementCode = (commodityCode : string , countryCode : string) : AxiosPromise<string> => axios.get(`${PACKMAN_API_URL}Consignment/AgreementCode?commodityCode=${commodityCode}&countryCode=${countryCode}`).then(res => res).catch((err) => { generalShowErrorSnackbar('An error occurred getting the agreement code.'); return err; });
export const upsertELot = (data : IELot) : AxiosPromise<IELotResponse> => axios.post(`${PACKMAN_API_URL}Consignment/PostELot`, data).then(res => res).catch((err) => { generalShowErrorSnackbar('An error occurred posting the ELot.'); return err; });
export const upsertTUMELot = (data : { complianceId : number; agreementCodeOverride ?: string | number}) : AxiosPromise<IELotResponse> => axios.post(`${PACKMAN_API_URL}Consignment/PostTUMELot`, data).then(res => res).catch((err) => { generalShowErrorSnackbar('An error occurred posting the ELot.'); return err; });
export const upsertTUMV3TradeUnit = (data : { complianceId : number; agreementCodeOverride ?: string | number}) : AxiosPromise<ITradeUnitResponse> => axios.post(`${PACKMAN_API_URL}Consignment/PostTUMV3TradeUnit`, data).then(res => res).catch((err) => { generalShowErrorSnackbar('An error occurred posting the trade unit.'); return err; });
export const generateTUMV3Json = (complianceId : number) : AxiosPromise<string> => axios.post(`${PACKMAN_API_URL}Consignment/GenerateTUMV3Json`, { complianceId }).then(res => res).catch((err) => { generalShowErrorSnackbar('An error occurred generating the Json.'); return err; });
