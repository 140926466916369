import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Typography, Dialog, Select, MenuItem, Button, Card, TextField, SelectChangeEvent } from '@mui/material';
import { ICompliance } from '../../@types/model/compliance/compliance';
import { IComplianceLine } from '../../@types/model/compliance/complianceLine';
import { compareDate, formatDateTime, booleanToYesNo, removeArrayElement, addArrayElement, getCertificateStockLineSummary, formatDateTimeToDateOnly } from '../../services/appFunctionsService';
import Screen from '../../components/Screen';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { IAuthState, IRootState, RootAction } from '../../@types/redux';
import CustomTable from '../../components/datagrid/CustomTable';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { ISite } from '../../@types/model/masterData/site/site';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import { IStock } from '../../@types/model/stock/stock';
import PillButton from '../../components/input/PillButton';
import { buildPDF, exportCompliance, exportFindingSheet, get202FileName, getStatus, mapComplianceToConsignment, mapConsignmentsToELot, onComplianceExport, onFileLoaded } from '../../services/compliance/documentService';
import { IConsignmentStorage } from '../../@types/model/compliance/titan-TUR/consignmentStorage';
import { createSelector } from 'reselect';
import PapaParse from 'papaparse';
import lodash, { flatten, uniq } from 'lodash';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import { ConsignmentCSVJson, IConsignmentCSVJson } from '../../@types/model/compliance/titan-TUR/consignmentCSVJson';
import PopupOptionButton from '../../components/button/PopupOptionButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import SettingsIcon from '@mui/icons-material/Settings';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import FileSelector from '../../components/input/FileSelector';
import { IGoogleCloudStorage } from '../../@types/model/googleCloudStorage/googleCloudStorage';
import GoogleCloudStorageHttpService from '../../services/googleCloudStorageService';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import Code39 from '../../fonts/code39.ttf';
import { DATE_FORMAT_DEFAULT, DATETIME_SECONDS_PALTRACK_FILE, PACKMAN_API_URL, PPECB_API_URL, TUM_API_URL } from '../../appConstants';
import moment from 'moment';
import { IInspectionPoint } from '../../@types/model/masterData/inspectionPoint/inspectionPoint';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { IMarket } from '../../@types/model/masterData/market/market';
import { ISize } from '../../@types/model/masterData/size/size';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import { IMark } from '../../@types/model/masterData/mark/mark';
import { IRegion } from '../../@types/model/masterData/region/region';
import { IOrchard } from '../../@types/model/masterData/orchard/orchard';
import PrintIcon from '@mui/icons-material/Print';
import { navPath } from '../../store/nav/Actions';
import ComplianceManualInspection from './ComplianceManualInspection';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PageviewIcon from '@mui/icons-material/Pageview';
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';
import { Inventory } from '../../@types/model/masterData/inventory/inventory';
import { VERSION } from '../../../version';
import { EmailFormValues, IEmailFormValues } from '../../@types/model/email/emailFormValues';
import { dataSetCompliance } from '../../store/data/Functions';
import { Formik, FormikActions } from 'formik';
import { IContactInfo } from '../../@types/model/masterData/contactInfo/contactInfo';
import EmailForm from '../../components/email/EmailForm';
import { generateTUMV3Json, getAgreementCode } from '../../services/inspectionService';
import { ICountry } from '../../@types/model/masterData/country/country';
import { IRight } from '../../@types/model/user/right';
import { RouteComponentProps, withRouter } from 'react-router';
import ComplianceHttpService from '../../services/http/compliance/complianceHttpService';
import { IComplianceAudit } from '../../@types/model/compliance/complianceAudit';
import { IPIFileEmail } from '../../@types/model/compliance/piFileEmail';
import { Email } from '../../@types/model/email/email';
import EmailHttpService from '../../services/http/integration/email/emailHttpService';
import { IPIFileUpload } from '../../@types/model/compliance/piFileUpload';
import FTPHttpService from '../../services/http/integration/ftp/ftpHttpService';
import FileSaver from 'file-saver';
import { PackmanLink } from '../../components/link/packmanLink';
import { PackmanLabel } from '../../components/label/PackmanLabel';
import Tooltip from '../../components/tooltip/tooltip';
import { BooleanFlag } from '../../components/label/BooleanFlag';
import { IStockLineCertificateSummary } from '../../@types/model/stock/stockLineCertificateSummary';

const path = 'InspectionCertificates/';

// Create styles
const styles = StyleSheet.create({
    section: {
        margin: 10,
        padding: 10,
        flex: 1,
    },
    page: {
        paddingTop: 25,
        paddingBottom: 65,
        marginBottom: 65,
    },
    pageNumber: {
        fontSize: 11,
        position: 'absolute',
        bottom: 35,
        right: 25,
    },
    pageMargin: {
        marginLeft: 25,
        marginRight: 25,
        flex: 1,
    },
    tableRow: {
        borderBottom: '1pt solid black',
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 8,
        flexDirection: 'row',
    },
    findingSheetColumn: {
        fontSize: 8,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 5,
        paddingTop: 5,
        borderRight: '1pt solid black',
        alignItems: 'center',
    },
    fdc: { flexDirection: 'column' },
    fdr: { flexDirection: 'row' },
    verticalText: {
        transform: 'rotate(90deg)',
        width: 80,
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    barcode: {
        fontFamily: 'code39',
        fontSize: 20,
        transform: 'scaleY(2)',
        top: 10,
    },
    barcodeLabel: {
        fontSize: 9,
        letterSpacing: 2.5,
        top: 20,
    },
    fs6: { fontSize: 6 },
    fs8: { fontSize: 8 },
    fs10: { fontSize: 10 },
    fs11: { fontSize: 11 },
    fs12: { fontSize: 12 },
    fs13: { fontSize: 13 },
    fs14: { fontSize: 14 },
    fs15: { fontSize: 15 },
    bold: { fontWeight: 'bold' },
    pt2: { paddingTop: 2 },
    pt5: { paddingTop: 5 },
    pt10: { paddingTop: 10 },
    pt35: { paddingTop: 35 },
    pt50: { paddingTop: 50 },
    pb2: { paddingBottom: 2 },
    pb5: { paddingBottom: 5 },
    pb10: { paddingBottom: 10 },
    pb35: { paddingBottom: 35 },
    pl2: { paddingLeft: 2 },
    pl4: { paddingLeft: 4 },
    pl5: { paddingLeft: 5 },
    pl40: { paddingLeft: 40 },
    pr2: { paddingRight: 2 },
    pr20: { paddingRight: 20 },
    pr80: { paddingRight: 80 },
    pr100: { paddingRight: 100 },
    mt5: { marginTop: 5 },
    mt10: { marginTop: 10 },
    mt20: { marginTop: 20 },
    mt35: { marginTop: 35 },
    mb5: { marginBottom: 5 },
    mb10: { marginBottom: 10 },
    mb35: { marginBottom: 35 },
    flx1: { flex: 1 },
    blw1: { borderLeft: '1pt solid black' },
    brw1: { borderRight: '1pt solid black' },
    btw1: { borderTop: '1pt solid black' },
    bbw1: { borderBottom: '1pt solid black' },
    jcc: { justifyContent: 'center' },
    ail: { alignItems: 'left' },
    aic: { alignItems: 'center' },
    aife: { alignItems: 'flex-end' },
    tac: { textAlign: 'center' },
    posa: { position: 'absolute' },
    w30: { width: 30 },
    w100: { width: 100 },
    w140: { width: 140 },
    w200: { width: 200 },
    w280: { width: 280 },
    h40: { height: 40 },
    h80: { height: 80 },
    h90: { height: 90 },
    t2: { top: 2 },
    t5: { top: 5 },
    certCol1: {
        left: 0,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 1,
        position: 'absolute',
    },
    certCol2: {
        left: 35,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 2,
        position: 'absolute',
    },
    certCol3: {
        left: 70,
        width: 30,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 3,
        position: 'absolute',
    },
    certCol4: {
        left: 100,
        width: 25,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 4,
        position: 'absolute',
    },
    certCol5: {
        left: 125,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 5,
        position: 'absolute',
    },
    certCol6: {
        left: 160,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 6,
        position: 'absolute',
    },
    certCol7: {
        left: 195,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 7,
        position: 'absolute',
    },
    certCol8: {
        left: 230,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 8,
        position: 'absolute',
    },
    certCol9: {
        left: 265,
        width: 25,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 9,
        position: 'absolute',
    },
    certCol10: {
        left: 290,
        width: 30,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 10,
        position: 'absolute',
    },
    certCol11: {
        left: 320,
        width: 70,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 11,
        position: 'absolute',
    },
    certCol12: {
        left: 390,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 12,
        position: 'absolute',
    },
    certCol13: {
        left: 425,
        width: 85,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 13,
        position: 'absolute',
    },
    certCol14: {
        left: 510,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 14,
        position: 'absolute',
    },
    onionCertCols: {
        certCol1: {
            left: 0,
            width: 30,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 1,
            position: 'absolute',
        },
        certCol2: {
            left: 30,
            width: 30,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 2,
            position: 'absolute',
        },
        certCol3: {
            left: 60,
            width: 25,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 3,
            position: 'absolute',
        },
        certCol4: {
            left: 85,
            width: 30,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 4,
            position: 'absolute',
        },
        certCol5: {
            left: 115,
            width: 30,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 5,
            position: 'absolute',
        },
        certCol6: {
            left: 145,
            width: 35,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 6,
            position: 'absolute',
        },
        certCol7: {
            left: 180,
            width: 35,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 7,
            position: 'absolute',
        },
        certCol8: {
            left: 215,
            width: 25,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 8,
            position: 'absolute',
        },
        certCol9: {
            left: 240,
            width: 30,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 9,
            position: 'absolute',
        },
        certCol10: {
            left: 270,
            width: 70,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 10,
            position: 'absolute',
        },
        certCol11: {
            left: 345,
            width: 45,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 11,
            position: 'absolute',
        },
        certCol12: {
            left: 390,
            width: 35,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 12,
            position: 'absolute',
        },
        certCol13: {
            left: 425,
            width: 85,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 13,
            position: 'absolute',
        },
        certCol14: {
            left: 510,
            width: 35,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 14,
            position: 'absolute',
        },
    },
});

Font.register({
    family: 'code39',
    src: Code39,
});

const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
};

interface IComplianceSummaryProps extends RouteComponentProps {
    selectedComplianceId : number;
    auth : IAuthState;
    consignments : Array<IConsignmentStorage>;
    organizations : Array<IOrganization>;
    sites : Array<ISite>;
    farms : Array<IFarm>;
    stockData : Array<IStock>;
    refreshData : () => void;
    setWaybill : (waybill : string) => void;
    inspectionPoints : Array<IInspectionPoint>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    packs : Array<IPack>;
    markets : Array<IMarket>;
    marks : Array<IMark>;
    regions : Array<IRegion>;
    sizes : Array<ISize>;
    contactInfos : Array<IContactInfo>;
    grades : Array<IGrade>;
    orchards : Array<IOrchard>;
    countries : Array<ICountry>;
    inventoryData : Array<Inventory>;
}

interface IComplianceSummaryState {
    selectedCompliance ?: ICompliance;
    selectedComplianceLatestAudit ?: IComplianceAudit;
    isLoading : boolean;
    isMoreActionsDialogOpen : boolean;
    requestExportCertificate ?: ICompliance;
    isMessageOpen : boolean;
    isMessageLoading : boolean;
    message : string;

    selectedFiles : Array<File>;
    openComplianceFilesUploadDialog : boolean;
    selectedExporter : 'PPECB' | 'AMTA' | unknown;

    isInspectionPopupOpen : boolean;
    manualInspectingCompliance ?: ICompliance;

    openComplianceFileDownloadDialog : boolean;
    complianceDownloadFiles ?: Array<any>;
    isEmailFormOpen : boolean;
    isIntegrationDetailsOpen : boolean;
    isMoreInfoOpen : boolean;
    isAuditFormOpen : boolean;
    complianceAudits : Array<IComplianceAudit>;
}

class ComplianceSummary extends React.Component<IComplianceSummaryProps, IComplianceSummaryState> {
    constructor(props : IComplianceSummaryProps) {
        super(props);

        this.state = {
            isLoading: false,
            isMoreActionsDialogOpen: false,
            isMessageOpen: false,
            isMessageLoading: false,
            message: '',

            selectedFiles: [],
            openComplianceFilesUploadDialog: false,
            openComplianceFileDownloadDialog: false,
            selectedExporter: 'PPECB',

            isInspectionPopupOpen: false,
            isEmailFormOpen: false,
            isIntegrationDetailsOpen : false,
            isMoreInfoOpen : false,
            isAuditFormOpen: false,
            complianceAudits: [],
        };
    }

    public componentDidMount = async () => {
        this.setLoading(true);
        try {
            const res = await ComplianceHttpService.getComplianceLatestAudit(this.props.selectedComplianceId ?? 0);
            const res2 = await ComplianceHttpService.getCompliance(this.props.selectedComplianceId);
            if (res && res2) {
                this.props.setWaybill(res2.data.waybill ?? '');

                this.setState({ selectedCompliance: res2.data, selectedComplianceLatestAudit: res.data, isLoading: false });
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while loading dispatch summary data.');
            this.setLoading(false);
        } finally {
            this.setLoading(false);
        }
    };

    private getOrganizationName = (orgId : number) => {
        const organizations = this.props.organizations;
        const organization = organizations && organizations.find(x => x.id === orgId);
        return organization ? organization.name : '';
    };

    private getCommodityName = (commodityId : number) => {
        const commodities = this.props.commodities;
        const commodity = commodities && commodities.find(x => x.id === commodityId);
        return commodity ? commodity.name : '';
    };

    private getStockBarcode = (row : IComplianceLine, stock : IStock) => {
        return stock ? <PackmanLink
            type={'transactions'}
            targetPage={'stock'}
            id={stock.id}
            text={stock.barcode} /> : '';
    };

    private onGenerateComplianceAudits = async () => {
        this.setState({ isAuditFormOpen: true });
        this.setLoading(true);

        try {
            const res = await ComplianceHttpService.getComplianceAudits(this.state.selectedCompliance?.id);

            if (res && res.data) {
                this.setState({ complianceAudits: res.data });
            }
        } catch (e) {
            generalShowErrorSnackbar('Failed to load compliance audit data');
        } finally {
            this.setLoading(false);
        }
    };

    private onCloseCompliancekAuditDialog = async () => {
        this.setState({ isAuditFormOpen: false });
    };

    private onPIFileGenerate = async () => {
        const compliance = this.state.selectedCompliance;
        this.setState({ isLoading: true });

        try {
            const res = await ComplianceHttpService.getPIFileDataForDownload(compliance?.id ?? 0);

            if (res && res.data) {
                const fileBlob = new Blob([res.data.fileContent], { type: 'text/plain' });
                FileSaver.saveAs(fileBlob, res.data.fileName);
            }
        } catch (ex) {
            if (!!ex && ex.status !== 500) {
                generalShowErrorSnackbar(ex.data.Message);
            } else {
                generalShowErrorSnackbar('Error occured loading PI file download data');
            }
        } finally {
            this.setState({ isLoading: false });
        }
    };

    public onEmailReset = async (formValues : IEmailFormValues, formikActions : FormikActions<IEmailFormValues>) => {
        formikActions.resetForm();
        this.closeEmailForm();
    };

    private onEmailSubmit = async (value : IEmailFormValues) => {
        if (this.state.selectedCompliance) {
            try {
                const data : IPIFileEmail = {
                    complianceId: this.state.selectedCompliance.id,
                    versionNumber: VERSION.version,
                    emailData: new Email(value, []),
                };

                const res = await EmailHttpService.emailPIFile(data);

                if (res) {
                    generalShowSuccessSnackbar('PI file successfully emailed');
                }

                this.closeEmailForm();
                const compliance = { ...this.state.selectedCompliance };
                compliance.piEmailed = true;
                dataSetCompliance(compliance);
                this.closeEmailForm();
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while emailing the PI file.');
            } finally {
                this.setLoading(false);
            }
        }
    };

    private showEmailForm = () => {
        this.setState({ isEmailFormOpen: true });
    };

    private closeEmailForm = () => {
        this.setState({ isEmailFormOpen: false });
    };

    private getCompliance = (props : IComplianceSummaryProps, state : IComplianceSummaryState) => state.selectedCompliance;
    private getUserEmail = (props : IComplianceSummaryProps) => props.auth.session?.user?.email;
    private getFarms = (props : IComplianceSummaryProps) => props.farms;
    private getContactInfos = (props : IComplianceSummaryProps) => props.contactInfos;
    private getComplianceAudits = (props : IComplianceSummaryProps, state : IComplianceSummaryState) => state.complianceAudits;

    private getComplianceAuditRows = createSelector(
        [this.getComplianceAudits],
        (complianceAudits : Array<IComplianceAudit>) => {
            return complianceAudits ?? [];
        }
    );

    private generateEmail = createSelector([this.getCompliance, this.getUserEmail, this.getFarms, this.getContactInfos], (compliance, userEmail, farms, contactInfos) => {
        const toAddresses = contactInfos.filter(x =>
            farms.find(y => uniq(flatten(compliance?.complianceLines?.map(z => z.stock?.stockLines?.map(a => a.farmId)))).some(z => z === y.id))?.contactInfoIds?.some(y => y === x.id) && !!x.email && x.isActive)?.map(x => x.email ?? '');

        const ccAddresses : Array<string> = !!userEmail ? [userEmail] : [];

        const subject = `Packman v${VERSION.version} - PI File - ${compliance?.waybill}`;

        const body = `Please find attached the PI File for compliance ${compliance?.waybill}`;

        const files : Array<string> = ['PI File'];

        return new EmailFormValues(subject, body, toAddresses, ccAddresses, files);
    });

    private setLoading = (isLoading : boolean) => this.setState({ isLoading });

    private getColumns = () => [
        { title: 'Barcode', field: 'stock', width: 170, containerComponent: this.getStockBarcode, enableSorting: true },
        { title: 'Inspected On', field: 'inspectedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableSorting: true },
        { title: 'Inspected?', field: 'isInspected', formatFunction: booleanToYesNo, type: 'boolean', enableSorting: true },
        { title: 'Replaced?', field: 'isReplaced', formatFunction: booleanToYesNo, type: 'boolean', enableSorting: true },
        { title: 'Rejected?', field: 'isRejected', formatFunction: booleanToYesNo, type: 'boolean', enableSorting: true },
        { title: 'Reject Reason 1', field: 'rejectReason1', enableSorting: true },
        { title: 'Reject Reason 2', field: 'rejectReason2', enableSorting: true },
        { title: 'Reject Reason 3', field: 'rejectReason3', enableSorting: true },
        { title: 'Created By', field: 'createdByName', enableSorting: true },
        { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableSorting: true },
        { title: 'Updated By', field: 'updatedByName', enableSorting: true },
        { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableSorting: true },
        { title: 'Active?', field: 'isActive', formatFunction: booleanToYesNo, type: 'boolean', enableSorting: true },
    ];

    private getRows = () => this.state.selectedCompliance?.complianceLines ?? [];

    private csvObjToString = (data : Array<IConsignmentCSVJson>) => {
        let string = '';
        const firstObject = data[0];
        if (!firstObject) {
            return '';
        }
        const keys = lodash.keys(firstObject);
        string += keys.toString() + '\n';

        data.forEach((x) => {
            keys.forEach((y, i) => string += (x[y] ? x[y] : '') + ((i === keys.length - 1) ? '' : ','));
            string += '\n';
        });
        return string;
    };

    private getConsignments = (props : IComplianceSummaryProps) => props.consignments;

    private getConsignment = createSelector([this.getConsignments, this.getCompliance],
        (consignments, compliance) => consignments.find(x => x.consignmentNo === compliance?.waybill));

    private download206 = async () => {
        const consignment = this.getConsignment(this.props, this.state);
        const filestr = consignment?.fileBase64;
        const fileName = consignment?.fileName;

        if (!consignment || !fileName) {
            generalShowErrorSnackbar('Could not find consignment');
            return;
        }

        const reader = new FileReader();
        // @ts-ignore
        reader.onload = (event : { target : { result : string } | null}) => {
            const csvData = PapaParse.parse(
                // @ts-ignore
                event.target.result,
                Object.assign({}, papaparseOptions),
            );

            const data : Array<IConsignmentCSVJson> = csvData.data;
            const csvString = this.csvObjToString(data);
            const blob = new Blob([csvString]);

            FileSaver.saveAs(blob, fileName);
        };

        if (filestr) {
            const byteCharacters = atob(filestr.split('base64,')[1]);
            const byteNumbers = lodash.map(byteCharacters, (x, i) => byteCharacters.charCodeAt(i));
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'text/csv' });
            reader.readAsText(blob);
        }
    };

    public openMessagePopup = () => this.setState({ isMessageOpen: true });
    public closeMessagePopup = () => this.setState({ isMessageOpen: false, message: '' });
    public setMessage = (message : string) => this.setState({ message });
    public setMessageIsLoading = (isMessageLoading : boolean) => this.setState({ isMessageLoading });

    private openMoreOptionsDialog = () => this.setState({ isMoreActionsDialogOpen: true });
    private closeMoreOptionsDialog = () => this.setState({ isMoreActionsDialogOpen: false });

    public openIntegrationDetailsPopup = () => this.setState({ isIntegrationDetailsOpen: true });
    public closeIntegrationDetailsPopup = () => this.setState({ isIntegrationDetailsOpen: false });

    private onComplianceExport = () => onComplianceExport(this.state.selectedCompliance);

    public export202 = () => {
        this.openMessagePopup();
        const compliance = this.state.selectedCompliance;
        const exportingCompliance = exportCompliance(compliance);
        const csvArr = exportingCompliance.filter((x, i) => i > 0).map(x => new ConsignmentCSVJson(x));
        const csvString = this.csvObjToString(csvArr);
        const fileName = get202FileName(compliance) ?? '';
        const file = new Blob([csvString]);
        onFileLoaded(csvArr, file, fileName, false, this.setMessageIsLoading, this.setMessage);
        this.onComplianceExport();
    };

    private onPIFileUpload = async () => {
        const compliance = this.state.selectedCompliance;

        // if ((this.props.farms.find(y => uniq(flatten(compliance?.complianceLines?.map(z => z.stock?.stockLines?.map(a => a.farmId)))).some(z => z === y.id))?.ftpDetailIds?.length ?? 0) < 1) {
        //     generalShowErrorSnackbar('No farms on this compliance have ftp details');
        //     return;
        // }
        if (!!compliance) {
            this.setLoading(true);

            try {
                const data : IPIFileUpload = {
                    complianceId: compliance.id,
                    versionNumber: VERSION.version,
                };
                const res = await FTPHttpService.uploadPIFile(data);

                if (res && res.data) {

                    const updatedCompliance : ICompliance = {
                        ...compliance,
                        piUploaded: true,
                    };
                    dataSetCompliance(updatedCompliance);
                    generalShowSuccessSnackbar('PI file successfully uploaded');
                }
            } catch (ex) {
                generalShowErrorSnackbar('Failed to upload PI file');
            } finally {
                this.setLoading(false);
            }
        }
    };

    private onFileRemove = (file : File) => {
        const index = this.state.selectedFiles?.findIndex(x => x.name === file.name);
        if (index > -1) {
            this.setState({ selectedFiles: removeArrayElement(this.state.selectedFiles, index) });
        }
    };

    private handleFileInputChange = (files : Array<File>) => {
        files.forEach(x => this.setState({ selectedFiles: addArrayElement(this.state.selectedFiles, x, 'end') }));
    };

    private openComplianceFilesUploadDialog = () => {
        this.setState({ openComplianceFilesUploadDialog: true });
    };

    private closeComplianceFilesUploadDialog = () => {
        this.setState({ openComplianceFilesUploadDialog: false, selectedFiles: [] });
    };

    private openComplianceFileDownloadDialog = () => {
        this.setState({ openComplianceFileDownloadDialog: true }, () => {
            if (this.state.selectedCompliance && this.state.selectedCompliance.siteId) {
                this.getComplianceFilesToBeDownloaded(this.state.selectedCompliance.siteId);
            }
        });
    };

    private closeComplianceFileDownloadDialog = () => {
        this.setState({ openComplianceFileDownloadDialog: false, complianceDownloadFiles: [] });
    };

    private getCountryCode = (compliance ?: ICompliance) => {
        return this.props.countries.find(x => x.id === compliance?.complianceLines[0].stock?.countryId)?.code ?? 'NL';
    };

    public downloadTUMV3JSON = async () => {
        if (!this.state.selectedCompliance) return;

        const data = await generateTUMV3Json(this.state.selectedCompliance.id);

        const currentTUMRequestFile = new Blob([JSON.stringify(JSON.parse(data.data))]);

        FileSaver.saveAs(currentTUMRequestFile, `tum_v3_request_${moment(moment.now()).format(DATETIME_SECONDS_PALTRACK_FILE)}.json`);

    };

    public downloadIntegrationJSON = async () => {
        if (!this.state.selectedCompliance) return;
        const compliance = { ...this.state.selectedCompliance };
        if (!compliance) return;

        const turToken = this.props.auth?.turToken?.access_token;

        if (!turToken) {
            generalShowErrorSnackbar('Could not find TUM token');
            return;
        }

        const currentAgreementCodeRequestFile = new Blob([`commodityCode: ${this.getCommodityCode(compliance?.commodityId ?? 0)}, countryCode: ${this.getCountryCode(compliance)}`]);
        FileSaver.saveAs(currentAgreementCodeRequestFile, `agreement_code_request_${moment(moment.now()).format(DATETIME_SECONDS_PALTRACK_FILE)}.json`);

        await getAgreementCode(this.getCommodityCode(compliance?.commodityId ?? 0), this.getCountryCode(compliance)).then(async (agreementCodeResult) => {
            const agreementCode = agreementCodeResult.data;
            const consignment = mapComplianceToConsignment(compliance, agreementCode);
            const eLot = mapConsignmentsToELot([consignment], agreementCode, turToken);

            const currentTUMRequestFile = new Blob([JSON.stringify(eLot)]);
            const currentPPECBRequestFile = new Blob([JSON.stringify(consignment)]);

            FileSaver.saveAs(currentTUMRequestFile, `tum_request_${moment(moment.now()).format(DATETIME_SECONDS_PALTRACK_FILE)}.json`);
            FileSaver.saveAs(currentPPECBRequestFile, `ppecb_request_${moment(moment.now()).format(DATETIME_SECONDS_PALTRACK_FILE)}.json`);
        });
    };

    private getComplianceFilesToBeDownloaded = async (siteId : number) => {
        const site = this.props.sites.find(x => x.id === siteId);
        if (site) {
            this.setLoading(true);
            const folderPath = path + `${site.code + '_' + site.guid}/${this.state.selectedCompliance?.waybill}/`;
            const complianceAvailableDownloadFiles = await GoogleCloudStorageHttpService.getListData(folderPath);

            if (complianceAvailableDownloadFiles) {
                this.setState({ complianceDownloadFiles: complianceAvailableDownloadFiles.data });
                this.setLoading(false);
            }
        }
    };

    private complianceFiles = (props : IComplianceSummaryProps, state : IComplianceSummaryState) => state.complianceDownloadFiles;

    private getDispatchFiles = createSelector(
        [this.complianceFiles],
        (complianceFiles : Array<any>) => {
            if (!complianceFiles) return [];

            return complianceFiles.map(x => this.getInspectionCertificate(x));
        },
    );

    private getInspectionCertificate = (inspectionCertificate : any) => {
        const inspectionCertificateName : string = inspectionCertificate.name;
        const name = inspectionCertificateName.split('/')[3];
        return (
            <Tooltip title={'Download Compliance File?'}>
                <Card style={{ minHeight: 40, maxHeight: 40 }} className={'h40 w350 m5 aic jcc curp'} onClick={() => this.downloadComplianceFile(name, inspectionCertificate.contentType)}>{name}</Card>
            </Tooltip>
        );
    };

    private downloadComplianceFile = async (fileName : string, contentType : string) => {
        const site = this.props.sites.find(x => x.id === this.state.selectedCompliance?.siteId);
        const filePath = path + `${site?.code + '_' + site?.guid}/${this.state.selectedCompliance?.waybill}/`;
        if (site) {
            try {
                this.setLoading(true);
                const res = await GoogleCloudStorageHttpService.downloadFile(filePath, fileName);

                if (res) {

                    const byteCharacters = atob(res.data);
                    const byteNumbers = lodash.map(byteCharacters, (x, i) => byteCharacters.charCodeAt(i));
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: contentType });
                    if (blob) {
                        FileSaver.saveAs(blob, fileName);
                        generalShowSuccessSnackbar('Compliance downloaded successfully.');
                        this.setLoading(false);
                    }
                }
            } catch (e) {
                generalShowErrorSnackbar('Error while downloading compliance file.');
                this.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('Selected compliance site not found!');
        }
    };

    private getSiteCodeAndGuid = (id ?: number) => {
        const props = this.props;
        const sites = props.sites;
        const site = sites.find(x => x.id === id);
        if (site) {
            return site.code + '_' + site.guid;
        }
    };

    private onComplianceFilesUpload = async () => {
        const files = this.state.selectedFiles;
        const selectedCompliance = this.state.selectedCompliance;
        const data : Array<IGoogleCloudStorage> = [];
        if (selectedCompliance && selectedCompliance.waybill !== undefined) {
            if (files.length > 0 && selectedCompliance) {
                this.setState({ isLoading: true });
                files.forEach((file) => {
                    const reader = new FileReader();
                    reader.onload = async () => {
                        const fileStr = reader.result;
                        if (typeof fileStr === 'string') {
                            const fileString = fileStr.substr(5);
                            const contentType = fileString.split(';')[0];
                            const startFromPos = fileString.split(',')[0].length;
                            const fileBase64 = fileString.substr((startFromPos + 1));

                            const fileToBeUploaded : IGoogleCloudStorage = {
                                fileName: file?.name,
                                destinationPath: `InspectionCertificates/${this.getSiteCodeAndGuid(selectedCompliance.siteId)}/${selectedCompliance.waybill}`,
                                contentType,
                                fileBase64,
                            };

                            data.push(fileToBeUploaded);

                            if (data.length === files.length) {
                                try {
                                    const res = await GoogleCloudStorageHttpService.uploadFile(data);
                                    if (res) {
                                        this.closeComplianceFilesUploadDialog();
                                        this.setState({ isLoading: false });
                                        generalShowSuccessSnackbar('File uploaded successfully.');
                                    }
                                } catch (e) {
                                    this.closeComplianceFilesUploadDialog();
                                    this.setState({ isLoading: false });
                                    generalShowErrorSnackbar('Error while uploading file.');
                                }
                            }
                        }
                    };
                    reader.readAsDataURL(file);
                });
            }
        } else {
            generalShowErrorSnackbar('The selected compliance does not have a waybill number!');
        }
    };

    private requestExportCertificateClick = () => this.requestExportCertificate(this.state.selectedCompliance);

    private requestExportCertificate = (requestExportCertificate ?: ICompliance) => this.setState({ requestExportCertificate });
    private cancelExportCertificate = () => this.setState({ requestExportCertificate: undefined });

    private exportCertificate = () => {
        if (this.state.requestExportCertificate) {
            buildPDF(this.complianceCertificate, `Compliance_Certificate_${this.state.requestExportCertificate.waybill}.pdf`, this.state.requestExportCertificate);
        }
        this.setState({ requestExportCertificate: undefined });
    };

    private getSiteInspectionPointCode = (siteId : number) => {
        const site = this.props.sites.find(x => x.id === siteId);
        const inspectionPoint = this.props.inspectionPoints.find(x => x.id === (site && site.inspectionPointId) && x.isActive);
        return inspectionPoint ? inspectionPoint.code : '';
    };

    private getSiteCode = (id : number) => {
        const item = this.props.sites.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getFarmCode = (id : number) => {
        const item = this.props.farms.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getFarmGlobalGapNumber = (id : number) => {
        const item = this.props.farms.find(x => x.id === id);
        return item ? item.globalGapNumber : '';
    };

    private getCommodityCode = (id : number) => {
        const item = this.props.commodities.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getVarietyCode = (id : number) => {
        const item = this.props.varieties.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getPackCode = (id : number) => {
        const item = this.props.packs.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getComplianceFarmCodes = (compliance : ICompliance) => {
        const farmIds : Array<number> = [];
        const stockIds = compliance.complianceLines.filter(x => x.isActive).map(x => x.stockId);

        const stocks = this.props.stockData.filter(x => x.isActive && stockIds.some(y => y === x.id));

        stocks.forEach(x => x.stockLines.forEach((y) => {
            if (!farmIds.some(z => z === y.farmId)) {
                farmIds.push(y.farmId);
            }
        }));

        return uniq(this.props.farms.filter(x => farmIds.some(y => y === x.id)).map(x => x.code)).toString().replace(/,/g, ', ');
    };

    private getComplianceFarmGGN = (compliance : ICompliance) => {
        const farmIds : Array<number> = [];
        const stockIds = compliance.complianceLines.filter(x => x.isActive).map(x => x.stockId);

        const stocks = this.props.stockData.filter(x => x.isActive && stockIds.some(y => y === x.id));

        stocks.forEach(x => x.stockLines.forEach((y) => {
            if (!farmIds.some(z => z === y.farmId)) {
                farmIds.push(y.farmId);
            }
        }));

        return uniq(this.props.farms.filter(x => farmIds.some(y => y === x.id)).map(x => x.globalGapNumber)).toString().replace(/,/g, ', ');
    };

    private getMarkCode = (id : number) => {
        const item = this.props.marks.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getInventoryCode = (id : number) => {
        const item = this.props.inventoryData.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getSizeCode = (id : number) => {
        const item = this.props.sizes.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getGradeCode = (id : number) => {
        const item = this.props.grades.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getMarketCode = (id : number) => {
        const item = this.props.markets.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getOrchardCode = (id ?: number) => {
        const item = this.props.orchards.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getOrchardName = (id ?: number) => {
        const item = this.props.orchards.find(x => x.id === id);
        return item ? item.name : '';
    };

    private getRegionCode = (id : number) => {
        const item = this.props.regions.find(x => x.id === id);
        return item ? item.code : '';
    };

    private complianceCertificate = (compliance : ICompliance) => <Document>
        {compliance.complianceLines.filter(x => x.isActive).some(x => !x.isRejected) && this.complianceCertificatePage(compliance, false)}
        {compliance.complianceLines.filter(x => x.isActive).some(x => x.isRejected) && this.complianceCertificatePage(compliance, true)}
    </Document>;

    private complianceCertificatePage = (compliance : ICompliance, rejected : boolean) => {
        const firstActiveComplianceLine = compliance.complianceLines.filter(x => x.isActive)[0];
        const inspectedOn = firstActiveComplianceLine.inspectedOn;
        // eslint-disable-next-line no-console
        console.log(firstActiveComplianceLine, compliance);

        const complianceStock = this.props.stockData.find(x => x.id === firstActiveComplianceLine.stockId);
        const firstActiveStockLine = complianceStock?.stockLines.filter(x => x.isActive)[0];
        let firstActiveStockLineCommodityCode = '';

        if (firstActiveStockLine) {
            firstActiveStockLineCommodityCode = this.getCommodityCode(firstActiveStockLine.commodityId);
        }
        return (
            <Page size='A4' style={styles.page}>
                <View style={styles.pageMargin}>
                    <View style={[styles.fdr, styles.aic]}>
                        <View style={styles.flx1} />
                        <Text style={[styles.fs15]}>{`${this.state.selectedExporter} Inspection Certificate`}</Text>
                        <View style={styles.w30} />
                        <Text style={[styles.fs8]}>{`Date Printed: ${moment(moment.now()).format(DATE_FORMAT_DEFAULT)}`}</Text>
                    </View>
                    <View style={[styles.jcc, styles.aic, styles.bbw1, styles.pt5, styles.pb5, styles.mb5]}>
                        <Image style={{ height: 40 }} source={`${ASSET_BASE}/assets/images/zz2-logo.jpg`} />
                    </View>
                    <View style={styles.fdr}>
                        <View style={[styles.fdc, styles.fs12]}>
                            <Text>From: Bertie van Zyl (Edms) Bpk</Text>
                            <Text style={styles.pl40}>Posbus 19</Text>
                            <Text style={styles.pl40}>Mooketsi 0825</Text>
                            <Text>Tel   : 015 395 2040</Text>
                            <Text>Fax  : 015 395 2052</Text>
                        </View>
                        <View style={styles.flx1} />
                        <View style={[styles.fdc, styles.pr100, styles.fs12]}>
                            <Text>{`FOR: ${this.getOrganizationName(compliance.organizationId)}`}</Text>
                        </View>
                    </View>
                    <View style={[styles.bbw1, styles.pt2, styles.pb5, styles.mb5, styles.fs12, styles.pr80, styles.aife]}>
                        <Text>{`Inspection Date: ${inspectedOn ? formatDateTimeToDateOnly(inspectedOn) : ''}`}</Text>
                    </View>
                    <View style={[styles.fdr, styles.fs10, styles.pt2, styles.pb2]}>
                        <View>
                            <Text>Inspector:</Text>
                        </View>
                        <View style={[styles.t2, styles.posa, { left: 130 }]}>
                            <Text>{compliance.inspectorId}</Text>
                        </View>
                        <View style={[styles.t2, styles.posa, { left: 240 }]}>
                            <Text>Intake Waybill:</Text>
                        </View>
                        <View style={[styles.t2, styles.posa, { left: 340 }]}>
                            <Text>{compliance.waybill}</Text>
                        </View>
                    </View>
                    <View style={[styles.fdr, styles.fs10, styles.pt2, styles.pb2]}>
                        <View>
                            <Text>Inspection Point:</Text>
                        </View>
                        <View style={[styles.t2, styles.posa, { left: 130 }]}>
                            <Text>{compliance.siteId ? this.getSiteInspectionPointCode(compliance.siteId) : ''}</Text>
                        </View>
                        <View style={[styles.t2, styles.posa, { left: 240 }]}>
                            <Text>Packhouse Code:</Text>
                        </View>
                        <View style={[styles.t2, styles.posa, { left: 340 }]}>
                            <Text>{compliance.siteId ? this.getSiteCode(compliance.siteId) : ''}</Text>
                        </View>
                    </View>
                    <View style={[styles.fdr, styles.fs10, styles.pt2, styles.pb2]}>
                        <View>
                            <Text>PUC(s):</Text>
                        </View>
                        <View style={[styles.t2, styles.posa, { left: 50 }]}>
                            <Text>{this.getComplianceFarmCodes(compliance)}</Text>
                        </View>
                        <View style={[styles.t2, styles.posa, { left: 240 }]}>
                            <Text>UPN:</Text>
                        </View>
                        <View style={[styles.t2, styles.posa, { left: 340 }]}>
                            <Text>{compliance.upn}</Text>
                        </View>
                    </View>
                    <View style={[styles.fdr, styles.fs10, styles.pt2, styles.pb5, styles.bbw1]}>
                        <View>
                            <Text>Global Gap Number(s):</Text>
                        </View>
                        <View style={[styles.t2, styles.posa, { left: 130 }]}>
                            <Text>{this.getComplianceFarmGGN(compliance)}</Text>
                        </View>
                    </View>
                    {firstActiveStockLineCommodityCode !== 'ON' // Temporarily removed variety column and added gross weight for Onions Issue No: 772
                        ?
                        <View style={styles.tableRow}>
                            <Text style={styles.certCol1}>Comm</Text>
                            <Text style={styles.certCol2}>Variety</Text>
                            <Text style={styles.certCol3}>Pack</Text>
                            <Text style={styles.certCol4}>Grd</Text>
                            <Text style={styles.certCol5}>Cnt</Text>
                            <Text style={styles.certCol6}>Inven</Text>
                            <Text style={styles.certCol7}>Mark</Text>
                            <Text style={styles.certCol8}>Farm</Text>
                            <Text style={styles.certCol9}>TM</Text>
                            <Text style={styles.certCol10}>Rgn</Text>
                            <Text style={styles.certCol11}>Orchard</Text>
                            <Text style={styles.certCol12}>Crtn</Text>
                            <Text style={styles.certCol13}>Barcode</Text>
                            <Text style={styles.certCol14}>Accept</Text>
                        </View>
                        :
                        <View style={styles.tableRow}>
                            <Text style={styles.onionCertCols.certCol1}>Comm</Text>
                            <Text style={styles.onionCertCols.certCol2}>Pack</Text>
                            <Text style={styles.onionCertCols.certCol3}>Grd</Text>
                            <Text style={styles.onionCertCols.certCol4}>Cnt</Text>
                            <Text style={styles.onionCertCols.certCol5}>Inven</Text>
                            <Text style={styles.onionCertCols.certCol6}>Mark</Text>
                            <Text style={styles.onionCertCols.certCol7}>Farm</Text>
                            <Text style={styles.onionCertCols.certCol8}>TM</Text>
                            <Text style={styles.onionCertCols.certCol9}>Rgn</Text>
                            <Text style={styles.onionCertCols.certCol10}>Orchard</Text>
                            <Text style={styles.onionCertCols.certCol11}>Gross</Text>
                            <Text style={styles.onionCertCols.certCol12}>Crtn</Text>
                            <Text style={styles.onionCertCols.certCol13}>Barcode</Text>
                            <Text style={styles.onionCertCols.certCol14}>Accept</Text>
                        </View>
                    }
                    {firstActiveStockLineCommodityCode !== 'ON'
                        ? compliance.complianceLines.filter(x => x.isActive).filter(x => x.isRejected === rejected).map(x => this.getTableRow(x))
                        : compliance.complianceLines.filter(x => x.isActive).filter(x => x.isRejected === rejected).map(x => this.getTableRowOnionSpecific(x))
                    }
                    <View style={styles.fdr}>
                        <Text style={[styles.fs11, styles.pt5, styles.posa, { left: 310 }]}>
                                    Total Cartons:    {this.getComplianceCartonCount(compliance)}
                        </Text>
                    </View>
                    { this.getCommodityCode(compliance.commodityId ?? 0) === 'ON' ?
                        <View style={styles.fdr}>
                            <Text style={[styles.fs11, styles.pt5, styles.posa, { top: 15, left: 310 }]}>
                                    Total Gross Weight:    {this.getComplianceGrossWeight(compliance)}
                            </Text>
                        </View>
                        : <View/>
                    }
                    <View style={styles.flx1} />
                    <View style={[styles.fdc, styles.mt5]}>
                        <Text style={styles.fs11}> {`Approved by Inspector:   ${compliance.inspectorId}`} </Text>
                        <View style={[styles.fdr, styles.mt35]}>
                            <Text style={styles.fs11}> Signature: </Text>
                            <View style={styles.flx1} />
                        </View>
                    </View>
                </View>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `Page ${pageNumber} of ${totalPages}`
                )} fixed> </Text>
            </Page>
        );
    };

    private getTableRow = (row : IComplianceLine) => {
        const stock = this.props.stockData.find(x => x.id === row.stockId);
        const stockLineSummary = getCertificateStockLineSummary(stock && stock.isActive ? stock.stockLines.filter(x => x.isActive) : []);
        return <View style={styles.fdc} key={'pdfcert_comp_line' + row.id}>
            {stock && stockLineSummary.map((line, index) => <View wrap={false} key={'pdfcert_comp_line' + row.id + '_' + stock.id + '_' + index} style={styles.tableRow}>
                <Text style={styles.certCol1}>{this.getCommodityCode(line.commodityId)}</Text>
                <Text style={styles.certCol2}>{this.getVarietyCode(line.varietyId)}</Text>
                <Text style={styles.certCol3}>{this.getPackCode(line.packId)}</Text>
                <Text style={styles.certCol4}>{this.getGradeCode(line.gradeId)}</Text>
                <Text style={styles.certCol5}>{this.getSizeCode(line.sizeId)}</Text>
                <Text style={styles.certCol6}>{this.getInventoryCode(stock.inventoryId)}</Text>
                <Text style={styles.certCol7}>{this.getMarkCode(stock.markId)}</Text>
                <Text style={styles.certCol8}>{this.getFarmCode(line.farmId)}</Text>
                <Text style={styles.certCol9}>{this.getMarketCode(stock.marketId)}</Text>
                <Text style={styles.certCol10}>{this.getRegionCode(stock.regionId)}</Text>
                <Text style={styles.certCol11}>{line.orchardId ? `${this.getOrchardCode(line.orchardId)} - ${this.getOrchardName(line.orchardId)}`.substr(0, 14) : ''}</Text>
                <Text style={styles.certCol12}>{line.cartons}</Text>
                <Text style={styles.certCol13}>{stock.barcode}</Text>
                <Text style={styles.certCol14}>{row.isRejected ? 'N' : 'Y'}</Text>
            </View>)}
        </View>;
    };

    private getTableRowOnionSpecific = (row : IComplianceLine) => { // Temporarily removed variety column and added gross weight for Onions Issue No: 772
        const stock = this.props.stockData.find(x => x.id === row.stockId);
        const stockLineSummary = getCertificateStockLineSummary(stock && stock.isActive ? stock.stockLines.filter(x => x.isActive) : []);
        return <View style={styles.fdc} key={'pdfcert_comp_line' + row.id}>
            {stock && stockLineSummary.map((line, index) => <View wrap={false} key={'pdfcert_comp_line' + row.id + '_' + stock.id + '_' + index} style={styles.tableRow}>
                <Text style={styles.onionCertCols.certCol1}>{this.getCommodityCode(line.commodityId)}</Text>
                <Text style={styles.onionCertCols.certCol2}>{this.getPackCode(line.packId)}</Text>
                <Text style={styles.onionCertCols.certCol3}>{this.getGradeCode(line.gradeId)}</Text>
                <Text style={styles.onionCertCols.certCol4}>{this.getSizeCode(line.sizeId)}</Text>
                <Text style={styles.onionCertCols.certCol5}>{this.getInventoryCode(stock.inventoryId)}</Text>
                <Text style={styles.onionCertCols.certCol6}>{this.getMarkCode(stock.markId)}</Text>
                <Text style={styles.onionCertCols.certCol7}>{this.getFarmCode(line.farmId)}</Text>
                <Text style={styles.onionCertCols.certCol8}>{this.getMarketCode(stock.marketId)}</Text>
                <Text style={styles.onionCertCols.certCol9}>{this.getRegionCode(stock.regionId)}</Text>
                <Text style={styles.onionCertCols.certCol10}>{line.orchardId ? `${this.getOrchardCode(line.orchardId)} - ${this.getOrchardName(line.orchardId)}`.substr(0, 14) : ''}</Text>
                <Text style={styles.onionCertCols.certCol11}>{this.getTableRowGrossWeight(stock, line)}</Text>
                <Text style={styles.onionCertCols.certCol12}>{line.cartons}</Text>
                <Text style={styles.onionCertCols.certCol13}>{stock.barcode}</Text>
                <Text style={styles.onionCertCols.certCol14}>{row.isRejected ? 'N' : 'Y'}</Text>
            </View>)}
        </View>;
    };

    private getTableRowGrossWeight = (stock : IStock, line : IStockLineCertificateSummary) => {
        return (stock.grossWeight / stock.cartons) * line.cartons;
    };

    private getComplianceGrossWeight = (compliance : ICompliance) => {
        let weight = 0;
        compliance.complianceLines.filter(x => x.isActive).forEach((x) => {
            const stock = this.props.stockData.find(y => y.id === x.stockId && y.isActive);
            if (stock) {
                weight += stock.grossWeight;
            }
        });
        return weight;
    };

    private getComplianceCartonCount = (compliance : ICompliance) => {
        let cartons = 0;
        compliance.complianceLines.filter(x => x.isActive).forEach((x) => {
            const stock = this.props.stockData.find(y => y.id === x.stockId && y.isActive);
            if (stock) {
                cartons += stock.cartons;
            }
        });
        return cartons;
    };

    private getRights = (props : IComplianceSummaryProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IComplianceSummaryProps) => props.location.pathname;

    private hasAdminRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_ADMIN')));

    private downloadFindingSheet = () => {
        const sites = this.props.sites;
        const siteCode = sites.find(x => x.id === this.state.selectedCompliance?.siteId)?.code;
        exportFindingSheet(this.state.selectedCompliance, siteCode);
    };

    private manualInspectCompliance = () => this.setState({ manualInspectingCompliance: this.state.selectedCompliance });

    private onManualInspectionClosed = (printPdf ?: boolean, newCompliance ?: ICompliance) => {
        this.setState({ manualInspectingCompliance: undefined });
        if (printPdf && newCompliance) {
            this.requestExportCertificate(newCompliance);
        }
    };

    private toggleInspectionPopupOpen = () => this.setState({ isInspectionPopupOpen: !this.state.isInspectionPopupOpen });
    private onGetStatusClick = () => {
        this.openMessagePopup();
        getStatus(this.state.selectedCompliance, this.setMessageIsLoading, this.setMessage, this.requestExportCertificate);
    };

    private onViewOnInspectionClick = () => navPath('/compliance/inspection');

    private renderInspectionPopup = () => {
        return <PackmanDialog maxWidth={'sm'} fullWidth isOpen={this.state.selectedCompliance !== undefined} isInfo title={'SELECT AN ACTION' + ' - ' + this.state.selectedCompliance?.waybill} onClose={this.toggleInspectionPopupOpen}>
            <div className={'fdc wfill hfill jcc aic pt10'}>
                <Tooltip title={this.state.selectedCompliance?.status !== 'Inspecting' ? 'Compliance status must  be inspecting to use this action.' : ''}>
                    <PopupOptionButton
                        text={'GET STATUS'}
                        icon={<AssignmentTurnedInIcon className=''/>}
                        disabled={this.state.isLoading || this.state.selectedCompliance?.status !== 'Inspecting'}
                        onClick={this.onGetStatusClick}
                    />
                </Tooltip>
                <PopupOptionButton
                    text={'VIEW ON INSPECTION'}
                    icon={<PageviewIcon className=''/>}
                    onClick={this.onViewOnInspectionClick}
                />
                <PopupOptionButton
                    text={'MANUAL INSPECTION'}
                    icon={<SearchIcon className=''/>}
                    disabled={this.state.isLoading}
                    onClick={this.manualInspectCompliance}
                />

            </div>
        </PackmanDialog>;
    };

    private onExportingInspectorSelect = (e : SelectChangeEvent<{}>) => this.setState({ selectedExporter: e.target.value });

    public render() {
        const { message, isMessageLoading, isMessageOpen } = this.state;
        return (
            <Screen isPadded={false} isScrollable={false} isLoading={this.state.isLoading}>
                {this.state.isInspectionPopupOpen && this.renderInspectionPopup()}
                <PackmanDialog
                    title={`Compliance Audits - ${this.state.selectedCompliance?.waybill}`}
                    className='mnw100'
                    maxWidth='lg'
                    fullWidth
                    isInfo={true}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isAuditFormOpen}
                    onClose={this.onCloseCompliancekAuditDialog}>
                    <div className={'p10'}>
                        <CustomTable<IComplianceAudit>
                            enableEditing={false}
                            enableSorting
                            enableFiltering
                            fitWidthToPage
                            enablePagination
                            columns={[
                                { title: 'Compliance Id', field: 'complianceId', enableFiltering: true, enableSorting: true },
                                { title: 'Status', field: 'status', enableFiltering: true, enableSorting: true },
                                { title: 'Compliance Active?', field: 'complianceIsActive', formatFunction: booleanToYesNo, type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Delete Reason', field: 'deleteReason', enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
                            ]}
                            rows={this.getComplianceAuditRows(this.props, this.state)}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IComplianceAudit) => row.complianceIsActive}
                        />
                    </div>
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <div className={'fdc pl10 pr10 pt10'}>
                        <div className={'fdr aic pt10'}>
                            <PackmanLabel
                                label={'Organization'}
                                value={this.getOrganizationName(this.state.selectedCompliance?.organizationId ?? 0)}
                            />
                            <PackmanLabel
                                label={'Commodity'}
                                value={this.getCommodityName(this.state.selectedCompliance?.commodityId ?? 0)}
                            />
                            <PackmanLabel
                                label={'Status'}
                                value={this.state.selectedCompliance?.status ?? ''}
                            />
                            <PackmanLabel
                                label={'Waybill'}
                                value={this.state.selectedCompliance?.waybill ?? ''}
                            />
                            <PackmanLabel
                                label={'UPN'}
                                value={this.state.selectedCompliance?.upn ?? ''}
                            />
                        </div>
                        <div className={'fdr aic pt10'}>
                            <PackmanLabel
                                label={'Inspector'}
                                value={this.state.selectedCompliance?.inspectorId ?? ''}
                            />
                            <PackmanLabel
                                label={'Created By'}
                                value={this.state.selectedCompliance?.createdByName ?? ''}
                            />
                            <PackmanLabel
                                label={'Created On'}
                                value={this.state.selectedCompliance?.createdOn ?? ''}
                            />
                            <PackmanLabel
                                label={'Updated By'}
                                value={this.state.selectedCompliance?.updatedByName ?? ''}
                            />
                            <PackmanLabel
                                label={'Updated On'}
                                value={this.state.selectedCompliance?.updatedOn ?? ''}
                            />
                        </div>
                        <div className={'fdr aic pt10'}>
                            <PackmanLabel
                                label={'Elot Key'}
                                value={this.state.selectedCompliance?.eLotKey ?? ''}
                            />
                            <PackmanLabel
                                label={'Inspection Message Id'}
                                value={this.state.selectedCompliance?.inspectionMessageId ?? ''}
                            />
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>Manual Inspection?</Typography>
                                <BooleanFlag value={this.state.selectedCompliance?.isManualInspection ?? false}/>
                            </div>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>PI Downloaded?</Typography>
                                <BooleanFlag value={this.state.selectedCompliance?.piDownloaded ?? false}/>
                            </div>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>PI Uploaded?</Typography>
                                <BooleanFlag value={this.state.selectedCompliance?.piUploaded ?? false}/>
                            </div>
                        </div>
                        <div className={'fdr aifs pt10'}>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>PI Emailed?</Typography>
                                <BooleanFlag value={this.state.selectedCompliance?.piEmailed ?? false}/>
                            </div>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>PPECB Submitted?</Typography>
                                <BooleanFlag value={this.state.selectedCompliance?.ppecbSubmitted ?? false}/>
                            </div>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>TUR Submitted?</Typography>
                                <BooleanFlag value={this.state.selectedCompliance?.turSubmitted ?? false}/>
                            </div>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>Active?</Typography>
                                <BooleanFlag value={this.state.selectedCompliance?.isActive ?? false}/>
                            </div>
                            <div className='flx1' />
                        </div>
                        <div className={'fdr aifs pt10 mxwfill'}>
                            <div className={'aic flx2'} style={{ maxWidth: 'calc(100% * 2/7)' }}>
                                {!this.state.selectedCompliance?.isActive ? <PackmanLabel
                                    label={'Delete Reason'}
                                    maxWidth={'100%'}
                                    className={'wrap2line mxwfill oh'}
                                    value={this.state.selectedComplianceLatestAudit?.deleteReason ?? ''}
                                /> : <></>}
                            </div>
                            <div className='flx3' />
                        </div>
                    </div>
                    <div className={'fdr p20 mxh500'}>
                        <CustomTable<IComplianceLine>
                            columns={this.getColumns()}
                            rows={this.getRows()}
                            fitWidthToPage
                            enableSorting
                            initialSortOrder={[{ columnName: 'stockId_Barcode', direction : 'asc' }]}
                            isActive={(row : IComplianceLine) => row.isActive}
                            warning={(row : IComplianceLine) => row.isRejected}
                        />
                    </div>
                    <div style={{ flexWrap: 'wrap' }} className={'fdr p20 wfill'}>
                        <div>
                            <PopupOptionButton
                                text={'Generate Compliance Audits'}
                                icon={<InfoIcon/>}
                                disabled={this.state.isLoading}
                                onClick={this.onGenerateComplianceAudits}
                            />
                        </div>
                        <div className={'w10'}/>
                        <Tooltip title={this.state.selectedCompliance?.status !== 'Approved' ? 'Compliance must be approved to use this action.' : ''}>
                            <PopupOptionButton
                                text={'DOWNLOAD COMPLIANCE FILES'}
                                icon={<GetAppIcon/>}
                                disabled={this.state.isLoading || this.state.selectedCompliance?.status !== 'Approved'}
                                onClick={this.openComplianceFileDownloadDialog}
                            />
                        </Tooltip>
                        <div className={'w10'}/>
                        <PopupOptionButton
                            text={'DOWNLOAD PI FILE'}
                            icon={<GetAppIcon/>}
                            disabled={this.state.isLoading}
                            onClick={this.onPIFileGenerate}
                        />
                        <div className={'w10'}/>
                        <Tooltip title={this.state.selectedCompliance?.status === 'Closed' ? 'Compliance status must not be closed to use these actions' : ''}>
                            <PopupOptionButton
                                text={'MORE UPLOAD/DOWNLOAD ACTIONS'}
                                icon={<SettingsIcon/>}
                                disabled={this.state.isLoading || this.state.selectedCompliance?.status === 'Closed'}
                                onClick={this.openMoreOptionsDialog}
                            />
                        </Tooltip>
                        <div className={'w10'}/>
                        <Tooltip title={['Draft', 'Planned', 'Partially Rejected', 'Closed'].some(x => x === this.state.selectedCompliance?.status) ? 'Compliance status must be inspecting or approved to use these actions' : ''}>
                            <PopupOptionButton
                                text={'MORE INSPECTION ACTIONS'}
                                icon={<SettingsIcon/>}
                                disabled={this.state.isLoading || ['Draft', 'Planned', 'Partially Rejected', 'Closed'].some(x => x === this.state.selectedCompliance?.status)}
                                onClick={this.toggleInspectionPopupOpen}
                            />
                        </Tooltip>
                        <div className={'w10'}/>
                        {
                            ['Approved', 'Partially Rejected', 'Closed'].some(x => x === this.state.selectedCompliance?.status) && this.hasAdminRight(this.props) &&
                            <PopupOptionButton
                                text={'INTEGRATION DETAILS'}
                                icon ={<InfoIcon/>}
                                disabled={this.state.isLoading}
                                onClick={this.openIntegrationDetailsPopup}
                            />
                        }
                        <div className={'flx1'} />
                    </div>
                </div>
                {/* Integration Details Dialog*/}
                <PackmanDialog
                    isOpen={this.state.isIntegrationDetailsOpen}
                    onClose={this.closeIntegrationDetailsPopup}
                    isInfo
                    fullScreen
                    title='Integration Details' >
                    <TextField
                        value={
                            '-- Tokens --\n\n' +
                            `PPECB Token: ${this.props.auth.ppecbToken?.token}\n` +
                            `TUM Token: ${this.props.auth.turToken?.access_token}\n\n\n` +

                            '-- Endpoints --\n\n' +
                            `TUM Token Request: ${PACKMAN_API_URL}Consignment/TURToken  ==>  https://uas.ecert.co.za/oauth2/token\n` +
                            `PPECB Token Request: ${PPECB_API_URL}oauth/ApiAuth\n` +
                            `Agreement Code Request: ${PACKMAN_API_URL}Consignment/AgreementCode  ==>  https://app.ecert.co.za/api/v1/Agreement/Get\n` +
                            `TUM Submit Request: ${PACKMAN_API_URL}Consignment/PostTUMELot  ==>  http://${TUM_API_URL}TrackingUnit/eLot\n` +
                            `PPECB Submit Request: ${PPECB_API_URL}pi/ProductInspection/consignment\n` +
                            `PPECB Get Status Request: ${PPECB_API_URL}pi/ProductInspection/inspectionResult\n`
                        }
                        multiline
                        variant={'outlined'}
                        disabled
                        className={'mnw800 mxh800 m5 ml20 mr20'}
                    />
                    <div className={'fdr m10 jcfe'}>
                        <Button className={'m5'} variant='contained' onClick={this.downloadIntegrationJSON}>
                            Download Json
                        </Button>
                        <div className={'flx1'} />
                        <Button className={'m5'} variant='contained' onClick={this.downloadTUMV3JSON}>
                            Download TUM V3 Json
                        </Button>
                        <div className={'flx1'} />
                        <Button className={'m5'} variant='contained' onClick={this.closeIntegrationDetailsPopup}>
                            Ok
                        </Button>
                    </div>
                </PackmanDialog>
                {/* More Actions Dialog */}
                <PackmanDialog
                    title={'SELECT AN ACTION' + ' - ' + this.state.selectedCompliance?.waybill}
                    maxWidth={'sm'}
                    fullWidth
                    isInfo
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isMoreActionsDialogOpen}
                    onClose={this.closeMoreOptionsDialog}>
                    <div className={'fdc aic p10'}>
                        <Tooltip title={['Draft', 'Partially Rejected', 'Approved', 'Closed'].some(x => x === this.state.selectedCompliance?.status) ? 'Compliance status must be planned or inspecting to use wthis action.' : ''}>
                            <PopupOptionButton
                                text={'SUBMIT FOR INSPECTION'}
                                icon ={<GetAppIcon/>}
                                disabled={this.state.isLoading || ['Draft', 'Partially Rejected', 'Approved', 'Closed'].some(x => x === this.state.selectedCompliance?.status)}
                                onClick={this.export202}
                            />
                        </Tooltip>
                        <div className={'fdc'}>
                            <Tooltip title={this.state.selectedCompliance?.status !== 'Approved' ? 'Compliance status must be approved to use this action.' : ''}>
                                <PopupOptionButton
                                    text={'UPLOAD PI FILE'}
                                    icon={<CloudUploadIcon/>}
                                    disabled={this.state.isLoading || this.state.selectedCompliance?.status !== 'Approved'}
                                    onClick={this.onPIFileUpload}
                                />
                            </Tooltip>
                            <Tooltip title={this.state.selectedCompliance?.status !== 'Approved' ? 'Compliance status must be approved to use this action.' : ''}>
                                <PopupOptionButton
                                    text={'EMAIL PI FILE'}
                                    icon={<CloudUploadIcon/>}
                                    disabled={this.state.isLoading || this.state.selectedCompliance?.status !== 'Approved'}
                                    onClick={this.showEmailForm}
                                />
                            </Tooltip>
                            <Tooltip title={this.state.selectedCompliance?.status !== 'Approved' ? 'Compliance status must be approved to use this action.' : ''}>
                                <PopupOptionButton
                                    text={'UPLOAD COMPLIANCE FILES'}
                                    icon ={<CloudUploadIcon/>}
                                    disabled={this.state.isLoading || this.state.selectedCompliance?.status !== 'Approved'}
                                    onClick={this.openComplianceFilesUploadDialog}
                                />
                            </Tooltip>
                        </div>
                        <div className={'fdc'}>
                            <Tooltip title={['Partially Rejected', 'Closed'].some(x => x === this.state.selectedCompliance?.status) ? 'Compliance status must not be partially rejected or closed to use this action.' : ''}>
                                <PopupOptionButton
                                    text={'DOWNLOAD FINDING SHEET'}
                                    disabled={this.state.isLoading || ['Partially Rejected', 'Closed'].some(x => x === this.state.selectedCompliance?.status)}
                                    icon={<ImportExportIcon className=''/>}
                                    onClick={this.downloadFindingSheet}
                                />
                            </Tooltip>
                            <Tooltip title={this.state.selectedCompliance?.status !== 'Approved' ? 'Compliance must be approved to use this action.' : ''}>
                                <PopupOptionButton
                                    text={'DOWNLOAD EXPORT CERTIFICATE'}
                                    icon={<PrintIcon/>}
                                    disabled={this.state.isLoading || this.state.selectedCompliance?.status !== 'Approved'}
                                    onClick={this.requestExportCertificateClick}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </PackmanDialog >
                {!!this.state.isEmailFormOpen &&
                    <PackmanDialog
                        title='Email PI File'
                        isEdit={true}
                        isLoading={this.state.isLoading}
                        isOpen={this.state.isEmailFormOpen}
                        onClose={this.closeEmailForm}>
                        <Formik
                            initialValues={this.generateEmail(this.props, this.state)}
                            isInitialValid={EmailFormValues.formSchema.isValidSync(this.generateEmail(this.props, this.state))}
                            onSubmit={this.onEmailSubmit}
                            onReset={this.onEmailReset}
                            enableReinitialize
                            validationSchema={EmailFormValues.formSchema}
                            component={EmailForm} />
                    </PackmanDialog >}
                <Dialog open={!!this.state.requestExportCertificate}>
                    <div className={'p20'}>
                        Select Exporting Inspector
                        <div className={'h10'} />
                        <Select
                            className={'wfill'}
                            value={this.state.selectedExporter || 'PPECB'}
                            onChange={this.onExportingInspectorSelect}
                        >
                            <MenuItem value={'PPECB'}>PPECB</MenuItem>
                            <MenuItem value={'AMTA'}>AMTA</MenuItem>
                        </Select>
                        <div className={'fdr mt10 mr10 ml10'}>
                            <Button className={'m5'} variant='contained' color='primary' onClick={this.exportCertificate}>
                                Ok
                            </Button>
                            <Button className={'m5'} variant='contained' color='primary' onClick={this.cancelExportCertificate}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Dialog>
                {/* Compliance Files Upload Dialog */}
                <PackmanDialog
                    title={'Upload Compliance Files'}
                    isInfo
                    isOpen={this.state.openComplianceFilesUploadDialog}
                    isLoading={this.state.isLoading}
                    onClose={this.closeComplianceFilesUploadDialog}>
                    <Screen isPadded={false} isScrollable={false}>
                        <div style={{ height: 500 }} className={'w400 aic fdc p20'}>
                            <div style={{ height: 400 }} className={'wfill'}>
                                <FileSelector
                                    accept={'image/*,application/pdf'}
                                    files={this.state.selectedFiles ? this.state.selectedFiles : []}
                                    disabled={this.state.isLoading}
                                    onFileSelected={this.handleFileInputChange}
                                    onFileRemoved={this.onFileRemove}
                                />
                            </div>
                            <div className={'flx1'}/>
                            <div className={'fdr pt10 pb10'}>
                                <Button
                                    className={'fwb h35'}
                                    variant='text'
                                    color='primary'
                                    onClick={this.closeComplianceFilesUploadDialog}>
                                        Cancel
                                </Button>
                                <PillButton
                                    disabled={this.state.selectedFiles.length === 0}
                                    className={'ml15 pl20 pr20 h35'}
                                    text={'Upload File'}
                                    color={'secondary'}
                                    onClick={this.onComplianceFilesUpload}
                                />
                            </div>
                        </div>
                    </Screen>
                </PackmanDialog >
                {/* Compliance Files Download Dialog */}
                <PackmanDialog
                    title={'Download Compliance files'}
                    isInfo
                    isLoading={this.state.isLoading}
                    isOpen={this.state.openComplianceFileDownloadDialog}
                    onClose={this.closeComplianceFileDownloadDialog}>
                    <Screen isPadded={false} isScrollable={false}>
                        <div style={{ maxHeight: 500, height: 500 }} className={'w400 aic fdc p20 oya'}>
                            {(this.state.complianceDownloadFiles && this.state.complianceDownloadFiles?.length > 0) ?
                                this.getDispatchFiles(this.props, this.state)
                                :
                                'No files available'
                            }
                        </div>
                    </Screen>
                </PackmanDialog >
                <PackmanDialog
                    title={`${!!this.state.manualInspectingCompliance && !!this.state.manualInspectingCompliance.waybill} - Manual Inspection`}
                    isInfo={true}
                    fullScreen={true}
                    isOpen={!!this.state.manualInspectingCompliance}
                    onClose={() => this.onManualInspectionClosed()}>
                    <ComplianceManualInspection
                        open={!!this.state.manualInspectingCompliance}
                        compliance={this.state.manualInspectingCompliance}
                        onClose={this.onManualInspectionClosed}
                        refreshData={this.props.refreshData}
                    />
                </PackmanDialog >
                <PackmanDialog
                    isOpen={isMessageOpen}
                    onClose={this.closeMessagePopup}
                    isLoading={isMessageLoading} isInfo title='Response' >
                    <TextField
                        value={message}
                        multiline
                        variant={'outlined'}
                        disabled
                        className={'mnw400 mxh800 m5'}
                    />
                </PackmanDialog>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        stockData: state.data.stocks,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        organizations: state.masterData.organizations,
        sites: state.masterData.sites,
        farms: state.masterData.farms,
        inspectionPoints: state.masterData.inspectionPoints,
        packs: state.masterData.packs,
        markets: state.masterData.markets,
        marks: state.masterData.marks,
        regions: state.masterData.regions,
        sizes: state.masterData.sizes,
        countries: state.masterData.countries,
        contactInfos: state.masterData.contactInfos,
        grades: state.masterData.grades,
        orchards: state.masterData.orchards,
        inventoryData: state.masterData.inventories,
    };
};

const mapDispatchToProps  = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(ComplianceSummary));
